@keyframes slideOut {
  0% {
    transform: translateY(0%);
  }

  10% {
    border-bottom-width: 0;
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

.slideIn {
  animation: .3s slideIn;
}

.slideOut {
  z-index: -10;
  animation: .3s forwards slideOut;
}

.drawer {
  border-bottom: 1px solid #ddd;
  position: relative;
}
