:local .wrapper {
  display: flex;
}

:local .decoratorWrapper {
  padding: 0 !important;
}

:local .defaultSearch {
  width: 50px;
  height: 50px;
  padding: 12px;
  font-size: 16px;
  font-weight: 300;
  overflow: hidden;
}

:local .smallSearch {
  width: 33px;
  height: 33px;
  padding: 7.5px;
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
}

:local .mediumSearch {
  width: 40px;
  height: 40px;
  padding: 9px;
  font-size: 14px;
  font-weight: 300;
  overflow: hidden;
}

:local .largeSearch {
  width: 50px;
  height: 50px;
  padding: 12px;
  font-size: 16px;
  font-weight: 300;
  overflow: hidden;
}

:local .defaultButton {
  z-index: 10;
  width: 50px;
  height: 50px;
  padding: 14px;
  font-size: 16px;
  font-weight: 300;
  overflow: hidden;
}

:local .smallButton {
  width: 33px;
  height: 33px;
  padding: 9.5px;
  font-size: 12px;
  font-weight: 300;
  overflow: hidden;
}

:local .mediumButton {
  width: 40px;
  height: 40px;
  padding: 11px;
  font-size: 14px;
  font-weight: 300;
  overflow: hidden;
}

:local .largeButton {
  width: 50px;
  height: 50px;
  padding: 14px;
  font-size: 16px;
  font-weight: 300;
  overflow: hidden;
}

:local .fullyTransparent {
  color: #222;
  background-color: #0000;
}

:local .whiteFill {
  fill: #fff;
}

:local .hideButton {
  opacity: 0;
  width: 0;
  padding: 0;
  transition: opacity .175s linear, width 0s linear .175s, padding 0s linear .175s !important;
}

:local .showButton {
  opacity: 1;
  transition: opacity .175s linear;
}

:local .shiftRight {
  margin-right: -1px;
}

:local .noClick {
  pointer-events: none;
}

:local .hideDropdown {
  opacity: 0;
  display: none;
}

.headerSearchButton {
  color: #000;
  padding: 10px;
}

.headerSearchClearButton {
  color: #000;
  padding: 13px;
}

.openDropdown {
  border: 1px solid #000;
  flex: 1;
}
