/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.bar {
  background: #fff;
  display: flex;
  justify-content: center;
  position: relative;
  height: 41px;
}

.barContent {
  width: calc(100% - (2 * 36px));
  width: calc(100% - 2 * var(--sassy-layout-offset));
  max-width: 1440px;
  position: static;
  align-items: center;
}

.wrapper {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}