/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.navigation {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
}

.items {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  align-content: center;
  cursor: pointer;
  display: flex;
}
.item:not(:first-child) > .fullWrapper {
  padding-left: 9px;
}
.item:not(:last-child) > .fullWrapper {
  padding-right: 9px;
}

.itemLink {
  text-decoration: none;
  color: #000;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  display: inline-block;
  line-height: 20px;
  position: relative;
}
.itemLink:hover {
  color: #000;
}
@media (max-width: 1200px) {
  .itemLink {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 300;
  }
}
.itemLink.pale {
  color: #bbb;
}

.linkUnderline {
  position: absolute;
  height: 3px;
  bottom: 0;
  width: 100%;
  left: 0;
  background: #222;
}

.linkUnderlineTrade {
  composes: linkUnderline;
  background: #222;
}

.dropdownContent {
  display: flex;
  width: calc(100% - (2 * 36px));
  width: calc(100% - 2 * var(--sassy-layout-offset));
  max-width: 1440px;
  margin: 0 auto;
  min-height: 432px;
}

.dropdownHeader {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  line-height: 24px;
}

.dropdownCol {
  padding: 27px 0;
  margin-left: 36px;
  width: 20%;
}
.dropdownCol:first-child {
  margin-left: 0;
}
.dropdownCol:last-child.imageModule {
  flex-grow: 1;
  width: 400px;
}

.dropdownLink {
  text-decoration: none;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  color: #222;
  display: block;
  padding-top: 9px;
  line-height: 32px;
}
.dropdownLink:hover {
  color: #222;
  text-decoration: underline;
}

.imageModuleWrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;
  height: 100%;
  width: 100%;
}

.imageModuleImage {
  height: 100%;
  left: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  opacity: 0;
  transition: opacity 0.5s;
}
.imageModuleImage.setWidth {
  height: auto;
  width: 100%;
}
.imageModuleImage.loadedImage {
  opacity: 1;
}

.imageModuleLink {
  display: block;
  height: 300px;
  margin-left: auto;
  width: 400px;
}

.imageBox {
  height: 200px;
  margin-left: auto;
  overflow: hidden;
  position: relative;
  width: 400px;
}

.titleBox {
  background: #fff;
  color: #222;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 100px;
  position: absolute;
  right: 0px;
  top: 200px;
  width: 400px;
}

.titleSecondary {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  margin: 18px 18px 0;
  width: 100%;
}

.titlePrimary {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin: 9px 18px;
  width: 100%;
}

.dropdownSection {
  margin-top: 20px;
}
.dropdownSection:nth-child(1) {
  margin-top: 0;
}