:local .decorator {
  padding: 0 9px;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
}
:local .iconContainer {
  max-width: 32px;
  min-width: 32px;
  padding-right: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
:local .iconContainerSmall {
  max-width: 30px;
  width: 30px;
  padding-right: 9px;
}
:local .iconContainerLarge {
  max-width: 45px;
  min-width: 45px;
}
:local .errorIcon {
  width: 100%;
  max-width: none !important;
  fill: #cc0000;
  animation: fadeIn 0.15s ease-in;
}
:local .validatedIcon {
  width: 100%;
  max-width: none !important;
  fill: #66a559;
  animation: fadeIn 0.15s ease-in;
}
:local .hiddenPlaceholder {
  display: none;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}