.container {
  color: #222;
  background: #f6f3eb;
  padding: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  position: relative;
}

.heading {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
}

.goldHeading {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #c2a661;
  padding-right: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 600;
}

.bodyText {
  padding-top: 9px;
  padding-bottom: 9px;
  display: block;
}

.promptBody {
  display: inline-flex;
}

.promptText {
  padding-top: 4px;
  display: inline-flex;
}

.promptText:hover {
  color: #c2a661;
}

.switch {
  padding-left: 9px;
}

.savingMessage {
  text-align: right;
  color: #888;
  height: 14px;
  padding-right: 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
}

.subText {
  color: #888;
}

.sectionDivider {
  background-color: #ddd;
  padding: 1px;
}
