/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.wrapper {
  position: relative;
  /*
  This is here because
  1. if the z-index is not set at 0 or above, the marketing banner, which itself has a z-index of -1, "sinks" and becomes unresponsive even when visible
  2. given that this wrapper has a z-index set at all, it must be set to 100 or more to stay above similar items images
  */
  z-index: 980;
  background-color: #fff;
}
.wrapper * {
  box-sizing: inherit;
}
@media print {
  .wrapper {
    display: none;
  }
}

.header {
  height: 94px;
  position: fixed;
  width: 100%;
  z-index: 980;
}
:global(.primary-resp-page-width) .header {
  min-width: 1024px;
}
:global(.primary-legacy-resp-page-width) .header {
  min-width: 768px;
}
.header.hasBanner {
  position: relative;
  height: 31px;
}
.header.hasBanner.isBannerOutOfScreen {
  position: fixed;
  top: -31px;
  height: 64px;
}
.header.disableSticky {
  height: 135px;
  position: static;
}
.header.disableSticky.hasBanner {
  margin-bottom: 31px;
}
.header.hasTopBarOnly {
  height: 94px;
}
.header.hideBottomBar {
  height: 64px;
}

.bottomWrapper.disableSticky {
  position: relative;
  border-bottom: 1px solid #ddd;
}

.shadow {
  height: 135px;
  position: relative;
  z-index: -2;
}
.shadow.hasTopBarOnly {
  height: 94px;
}
.shadow.disableSticky {
  display: none;
}
.shadow.isBannerOutOfScreen {
  height: 166px;
}
.shadow.hideBottomBar {
  height: 64px;
}

.bar {
  background: #222;
  display: flex;
  justify-content: center;
  position: relative;
}

.barContent {
  width: calc(100% - (2 * 36px));
  width: calc(100% - 2 * var(--sassy-layout-offset));
  max-width: 1440px;
  align-items: center;
  position: static;
}

.headerLink {
  text-decoration: none;
  color: #fff;
}
.headerLink a:hover, .headerLink:hover {
  color: #fff;
  text-decoration: underline;
}

.skipToContent {
  position: fixed;
  top: 9px;
  left: 9px;
  transition: none !important;
}
.skipToContent:not(:focus) {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
.skipToContent:focus {
  z-index: 10020;
}