:local .animatedPlaceholderContainer {
  flex-grow: 1;
  position: relative;
}
:local .animatedPlaceholderStart {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  color: #666;
  left: 1px;
  pointer-events: none;
  position: absolute;
  line-height: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  top: 0px;
  transition: font-size 0.1s ease, line-height 0.1s ease, top 0.1s ease;
  z-index: 10;
}
:local .animatedPlaceholderEnd {
  font-size: 12px;
  font-weight: 300;
  display: block;
  top: 8px;
  line-height: 12px;
}
:local .marginLeftMedium {
  margin-left: 18px;
}
:local .marginLeftSmall {
  margin-left: 9px;
}
:local .marginRightMedium {
  margin-right: 18px;
}
:local .marginRightSmall {
  margin-right: 9px;
}
:local .placeholderPaddingLeftMedium {
  padding-left: 18px;
}
:local .placeholderPaddingLeftSmall {
  padding-left: 9px;
}