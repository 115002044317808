.wrapper {
  height: 100%;
  display: flex;
  position: relative;
}

.noHover {
  pointer-events: none;
}

.authButtons {
  align-items: center;
  display: flex;
}

.authButton {
  color: #000;
  cursor: pointer;
  cursor: pointer;
  background: none;
  border: none;
  border-left: 1px solid #222;
  padding: 0 9px;
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  transition: color .2s;
}

.authButton:hover {
  color: #444;
}

@media (hover: none) {
  .authButton:hover {
    color: #000;
  }
}

.authButton:first-child {
  border: none;
}

.dropdownBodyWrapper {
  top: 100%;
}

.dropdown:before {
  z-index: 990;
  top: calc(100% - 10px);
}

.dropdownContent {
  padding: 18px 27px;
}

.dropdownLinkWrapper {
  padding: 5px;
  position: relative;
}

.badgeContainer {
  position: absolute;
  right: 0;
}

.dropdownLink {
  color: #000;
  cursor: pointer;
  justify-content: space-between;
  text-decoration: none;
  transition: color .2s;
  display: flex;
}

.dropdownLink:hover {
  color: #444;
}

@media (hover: none) {
  .dropdownLink:hover {
    color: #000;
  }
}

.unreadCount, .unreadCount:hover {
  color: #c00;
}
