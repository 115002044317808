.accountIcon {
  cursor: pointer;
  display: inline-block;
  margin: 0 9px 0 18px;
  position: relative;
  height: 18px;
  width: 18px;
  color: inherit;
}
.accountIcon:hover {
  color: inherit;
}

.accountWrapper {
  text-decoration: none;
  color: #000;
  align-items: center;
  display: flex;
  cursor: pointer;
  height: 100%;
}
.accountWrapper:hover {
  color: #000;
}

.maskIcon {
  color: inherit;
  cursor: pointer;
  display: inline-block;
  height: 15px;
  margin-left: 9px;
  width: 15px;
}

.maskDirectLoginIcon {
  position: relative;
  top: -2px;
  width: 25px;
  height: 25px;
  margin-right: 9px;
}

.nameWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-height: 32px;
}

.name {
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  color: inherit;
  transition: color 0.4s ease;
  display: flex;
  height: 16px;
  padding-top: 1px;
  line-height: 16px;
}
.name:hover {
  color: #444;
}
@media (hover: none) {
  .name:hover {
    color: #000;
  }
}
.name:hover {
  color: #222;
}

.nameTrade {
  composes: name;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 600;
  padding-top: 4px;
}

.tradeStatus,
.tradePoints {
  margin-top: 9px;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
  line-height: 16px;
}
.tradeStatus:hover,
.tradePoints:hover {
  color: #444;
}
@media (hover: none) {
  .tradeStatus:hover,
  .tradePoints:hover {
    color: #000;
  }
}
.tradeStatus:hover,
.tradePoints:hover {
  color: #222;
}

.tradePoints::after {
  content: "|";
  padding: 0 9px;
  color: #222;
}