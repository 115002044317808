.accountIcon {
  cursor: pointer;
  width: 18px;
  height: 18px;
  color: inherit;
  margin: 0 9px 0 18px;
  display: inline-block;
  position: relative;
}

.accountIcon:hover {
  color: inherit;
}

.accountWrapper {
  color: #000;
  cursor: pointer;
  align-items: center;
  height: 100%;
  text-decoration: none;
  display: flex;
}

.accountWrapper:hover {
  color: #000;
}

.maskIcon {
  color: inherit;
  cursor: pointer;
  width: 15px;
  height: 15px;
  margin-left: 9px;
  display: inline-block;
}

.maskDirectLoginIcon {
  width: 25px;
  height: 25px;
  margin-right: 9px;
  position: relative;
  top: -2px;
}

.nameWrapper {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  min-height: 32px;
  display: flex;
}

.name {
  color: inherit;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  height: 16px;
  padding-top: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  text-decoration: none;
  transition: color .4s;
  display: flex;
}

@media (hover: none) {
  .name:hover {
    color: #000;
  }
}

.name:hover {
  color: #222;
}

.nameTrade {
  composes: name;
  padding-top: 4px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}

.tradeStatus, .tradePoints {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #000;
  cursor: pointer;
  margin-top: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  text-decoration: none;
  transition: color .2s;
}

@media (hover: none) {
  .tradeStatus:hover, .tradePoints:hover {
    color: #000;
  }
}

.tradeStatus:hover, .tradePoints:hover {
  color: #222;
}

.tradePoints:after {
  content: "|";
  color: #222;
  padding: 0 9px;
}
