.wrapper {
  display: flex;
}

.iconWrapper {
  text-decoration: none;
  color: #000;
  display: inline-block;
  line-height: 0;
  max-height: 45px;
  width: 130px;
  text-align: center;
  transition: 0.3s all ease;
}
.iconWrapper:hover {
  color: #000;
}
@media (max-width: 768px) {
  .iconWrapper {
    width: 120px;
  }
}

.stickyLogo {
  width: 88px;
}