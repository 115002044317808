/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.iconWrapper {
  align-items: center;
  display: flex;
}

.noHover {
  pointer-events: none;
}

.icon {
  cursor: pointer;
  margin: 0 9px;
  padding-top: 9px;
  position: relative;
  width: 18px;
  bottom: 1px;
  text-decoration: none;
  color: #000;
}
.icon:hover {
  color: #000;
}

.countWrapper {
  font-size: 12px;
  font-weight: 300;
  position: absolute;
  width: 100%;
}

.count {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 10px;
  font-weight: 300;
  position: relative;
  bottom: 3px;
  left: 16px;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
}

.maxCount {
  padding-left: 3px;
}

.emptyBag {
  font-size: 14px;
  padding: 18px;
  text-align: center;
}

.dropdownBodyWrapper {
  top: 100%;
}

.dropdown:before {
  top: calc(100% - 9px - 1px);
  z-index: 990;
}

.dropdownTotalItemsRow {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  border-bottom: 1px solid #ddd;
  padding: 9px 18px;
}

.dropdownItemRow {
  cursor: pointer;
  width: 100%;
  display: flex;
  text-align: left;
  text-decoration: none;
  border-bottom: 1px solid #ddd;
  padding: 18px 0;
  transition: background 0.4s ease;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}
.dropdownItemRow:hover {
  background: #f3f3f3;
}

.dropdownRowLink {
  color: #222;
}

.dropdownItemColumn {
  padding: 0 18px;
}

.dropdownItemImageWrapper {
  height: 75px;
  width: 75px;
  position: relative;
}

.dropdownItemImage {
  margin: auto;
  max-width: 75px;
  max-height: 75px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.dropdownItemTitle > a {
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
}
.dropdownItemTitle > a:hover {
  color: #444;
}
@media (hover: none) {
  .dropdownItemTitle > a:hover {
    color: #000;
  }
}

.alert {
  color: #950808;
}