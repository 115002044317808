:local .errorMessage {
  color: #cc0000;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  margin-top: 9px;
  width: 100%;
}
:local .errorMessage.showErrorAsNote {
  color: #222;
}
:local .errorMessageTransition {
  opacity: 0;
  transition: opacity 0.15s ease-in;
  animation: fadeIn 0.15s ease-in;
}
:local .errorMessageTransition.activeErrorMessageTransition {
  opacity: 1;
}
:local .errorMessageRight {
  text-align: right;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}