/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.bar {
  background: #fff;
  display: flex;
  justify-content: center;
  position: relative;
  box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0);
  z-index: 10;
  height: 94px;
  /*
    Element(s) that will transform start out rendered in 3D so browsers--I'm looking at your Safari--don't have to
    switch in and out of rendering modes causing DOM elements to flicker
    http://stackoverflow.com/a/8948115
  */
  -webkit-transform: translateZ(0);
}

.stickyBar {
  box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.08);
  height: 64px;
  transition: all 0.3s ease;
}

.barContent {
  width: calc(100% - (2 * 36px));
  width: calc(100% - 2 * var(--sassy-layout-offset));
  max-width: 1440px;
  position: static;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  align-items: center;
  flex: 1;
}

.rightContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  height: 60px;
}