/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.noHover {
  pointer-events: none;
}

.icon {
  margin: 0 9px;
  width: 18px;
  position: relative;
  background: none;
  border: none;
  padding: 0;
  display: grid;
  text-decoration: none;
  color: #000;
}
.icon:hover {
  color: #000;
}

.hasUnread:after {
  display: block;
  content: "";
  position: absolute;
  right: -4px;
  top: -1px;
  width: 9px;
  height: 9px;
  border-radius: 999px;
  background: #cc0000;
}

.contentContainer {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  color: #222;
}

.emptyContainer {
  padding: 18px;
}

.actionLink {
  text-decoration: initial;
}

.feedList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.feedListItem {
  margin: 0;
  padding: 0;
}

.row {
  cursor: pointer;
  outline-offset: -5px;
  display: flex;
  padding: 18px;
  border-bottom: 1px solid #ddd;
  transition: background 0.4s ease;
  text-decoration: none;
}
.row:hover, .row:focus {
  color: unset;
  background-color: #f3f3f3;
}

.thumbnail {
  width: 50px;
  height: 50px;
  align-self: center;
}

.thumbnailImage {
  max-width: 100%;
  max-height: 50px;
}

.details {
  display: flex;
  width: 80%;
  padding-left: 18px;
}

.detailsNoThumbnail {
  composes: details;
  padding-left: 0;
}

.detailsSecondary {
  font-size: 13px;
}

.detailsLeft {
  width: 75%;
}

.detailsRight {
  align-items: flex-end;
  color: #222;
  text-align: right;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 9px;
}

.detailsSubject {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.detailsSubject,
.rewardAmount {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
}

.rewardsSubject {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 300;
}

.spinnerContainer {
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.spinnerWrapper {
  margin-right: 27px;
  margin-bottom: 27px;
}

.emailIcon {
  width: 16px;
}

.arrowIcon {
  color: #a48e45;
  width: 9px;
  height: 9px;
  display: inline-block;
  position: relative;
  margin-left: 9px;
}

.hideOverlay {
  display: none;
}

.activityFeedContainer .row:last-child {
  border-bottom: none;
}

.rewardsIconWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.rewardsIconContainer {
  height: 50px;
  width: 50px;
}

.rewardsIconLabel {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: italic;
  font-weight: normal;
  letter-spacing: 0;
  font-size: 9px;
  letter-spacing: -0.5px;
  position: relative;
  transform: rotate(-5deg);
  left: 18px;
  top: 16px;
}

.verticalOffset1 {
  position: relative;
  bottom: 1px;
}

.verticalOffset2 {
  position: relative;
  bottom: 2px;
}

.dropdownBodyWrapper {
  top: 100%;
}

.dropdown:before {
  top: calc(100% - 9px - 1px);
  z-index: 990;
}

.dropdownFooter {
  padding: 9px;
  text-align: center;
}

.rewardsLastChild {
  border-bottom: none;
}

.code {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
  margin: 0 5px;
}

.promoDetail {
  width: 125%;
}

.promoIconWrapper {
  color: #c2a661;
  width: 50px;
  height: 50px;
  display: inline-block;
  position: relative;
  justify-content: center;
}

.promoIconImg {
  height: 100%;
  width: 100%;
}

.notification {
  position: relative;
  padding: 18px;
  display: block;
  text-decoration: none;
}
.notification:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.notification:hover {
  color: unset;
  background: #f3f3f3;
}
.notification.unread {
  background: #f3f3f3;
}
.notification.unread::before {
  background: #c2a661;
  content: "";
  position: absolute;
  top: 50%;
  left: 7px;
  border-radius: 50%;
  width: 7px;
  height: 7px;
}

.heading {
  display: flex;
  justify-content: space-between;
}

.headingDetail {
  color: #222;
}

.notificationBody {
  display: flex;
  margin: 9px 0;
}

.arrow {
  color: #c2a661;
  width: 12px;
  height: 12px;
  margin-left: 18px;
  align-self: center;
}

.hidden {
  display: none;
}