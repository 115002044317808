.items {
  align-items: center;
  max-width: 400px;
  margin: 0 18px;
  display: flex;
}

@media (width <= 768px) {
  .items {
    margin-top: 18px;
  }
}

.item {
  flex: 0 0 20%;
  margin: 0;
}

.image {
  max-width: 100%;
  max-height: 70px;
  margin: auto;
  padding: 9px;
  display: block;
}
