.item {
  padding: 0;
}
.item.active, .item:hover {
  background: #f6f3eb;
}
@media (max-width: 768px) {
  .item {
    padding: 9px 0;
  }
}

.link {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
  color: #000;
  cursor: pointer;
  display: inline-block;
  padding: 4px 9px;
  width: 100%;
  text-decoration: none;
}
.link:hover, .link:visited {
  color: #222;
}
@media (max-width: 768px) {
  .link {
    padding: 0 18px;
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 18px;
    font-weight: 300;
  }
}