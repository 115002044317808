.bar {
  background: #fff;
  justify-content: center;
  height: 41px;
  display: flex;
  position: relative;
}

.barContent {
  width: calc(100% - 72px);
  width: calc(100% - 2 * var(--sassy-layout-offset));
  align-items: center;
  max-width: 1440px;
  position: static;
}

.wrapper {
  justify-content: flex-end;
  height: 100%;
  display: flex;
}
