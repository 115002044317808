.iconWrapper {
  align-items: center;
  display: flex;
}

.icon {
  cursor: pointer;
  color: #000;
  width: 18px;
  height: 18px;
  margin: 0 9px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.icon:hover {
  color: #000;
}

.hasUnread:after {
  content: "";
  background: #c00;
  border-radius: 999px;
  width: 9px;
  height: 9px;
  display: block;
  position: absolute;
  top: -1px;
  right: -4px;
}
