@keyframes slideOut {
  0% {
    transform: translateY(0%);
  }
  10% {
    border-bottom-width: 0;
  }
  100% {
    transform: translateY(-100%);
  }
}
@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
.slideIn {
  animation: slideIn 0.3s;
}

.slideOut {
  animation: slideOut 0.3s forwards;
  z-index: -10;
}

.drawer {
  position: relative;
  border-bottom: 1px solid #ddd;
}