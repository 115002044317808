.items {
  display: flex;
  align-items: center;
  margin: 0 18px;
  max-width: 400px;
}
@media (max-width: 768px) {
  .items {
    margin-top: 18px;
  }
}

.item {
  margin: 0;
  flex: 0 0 20%;
}

.image {
  display: block;
  max-height: 70px;
  max-width: 100%;
  margin: auto;
  padding: 9px;
}