.wrapper {
  color: #222;
  align-items: center;
  height: 100%;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  display: flex;
  position: relative;
}

.dropdown {
  opacity: 0;
  visibility: hidden;
  transition: opacity .2s, visibility .2s;
}

.dropdown.hasDropdownHeader:before {
  border-bottom: 10px solid #f3f3f3;
}

.dropdown:before {
  content: "";
  z-index: 200;
  border: 10px solid #0000;
  border-top: 0;
  border-bottom-color: #fff;
  width: 0;
  height: 0;
  transition: opacity .2s, visibility .2s;
  display: block;
  position: absolute;
  top: calc(100% - 10px);
  left: calc(50% - 10px);
}

.isVisible {
  visibility: visible;
  opacity: 1;
}

.bodyWrapper {
  z-index: 980;
  background: #fff;
  position: absolute;
  top: 100%;
  box-shadow: 1px 2px 8px #22222240;
}

.bodyWrapper.small {
  width: 250px;
}

.bodyWrapper.medium {
  width: 350px;
}

.bodyWrapper.large {
  width: 450px;
}

.bodyWrapper.extraLarge {
  width: 550px;
}

.body {
  max-height: 600px;
  position: relative;
  overflow-y: scroll;
}

.header {
  background-color: #f3f3f3;
  justify-content: space-between;
  padding: 9px 18px;
  display: flex;
}

.headerTitle {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.headerLink {
  font-size: 13px;
}
