.noHover {
  pointer-events: none;
}

.icon {
  color: #000;
  background: none;
  border: none;
  width: 18px;
  margin: 0 9px;
  padding: 0;
  text-decoration: none;
  display: grid;
  position: relative;
}

.icon:hover {
  color: #000;
}

.hasUnread:after {
  content: "";
  background: #c00;
  border-radius: 999px;
  width: 9px;
  height: 9px;
  display: block;
  position: absolute;
  top: -1px;
  right: -4px;
}

.contentContainer {
  color: #222;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.emptyContainer {
  padding: 18px;
}

.actionLink {
  -webkit-text-decoration: initial;
  text-decoration: initial;
}

.feedList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.feedListItem {
  margin: 0;
  padding: 0;
}

.row {
  cursor: pointer;
  outline-offset: -5px;
  border-bottom: 1px solid #ddd;
  padding: 18px;
  text-decoration: none;
  transition: background .4s;
  display: flex;
}

.row:hover, .row:focus {
  color: unset;
  background-color: #f3f3f3;
}

.thumbnail {
  align-self: center;
  width: 50px;
  height: 50px;
}

.thumbnailImage {
  max-width: 100%;
  max-height: 50px;
}

.details {
  width: 80%;
  padding-left: 18px;
  display: flex;
}

.detailsNoThumbnail {
  composes: details;
  padding-left: 0;
}

.detailsSecondary {
  font-size: 13px;
}

.detailsLeft {
  width: 75%;
}

.detailsRight {
  color: #222;
  text-align: right;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 25%;
  margin-bottom: 9px;
  display: flex;
}

.detailsSubject {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.detailsSubject, .rewardAmount {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
}

.rewardsSubject {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
}

.spinnerContainer {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  display: flex;
}

.spinnerWrapper {
  margin-bottom: 27px;
  margin-right: 27px;
}

.emailIcon {
  width: 16px;
}

.arrowIcon {
  color: #a48e45;
  width: 9px;
  height: 9px;
  margin-left: 9px;
  display: inline-block;
  position: relative;
}

.hideOverlay {
  display: none;
}

.activityFeedContainer .row:last-child {
  border-bottom: none;
}

.rewardsIconWrapper {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.rewardsIconContainer {
  width: 50px;
  height: 50px;
}

.rewardsIconLabel {
  letter-spacing: 0;
  letter-spacing: -.5px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 9px;
  font-style: italic;
  font-weight: normal;
  position: relative;
  top: 16px;
  left: 18px;
  transform: rotate(-5deg);
}

.verticalOffset1 {
  position: relative;
  bottom: 1px;
}

.verticalOffset2 {
  position: relative;
  bottom: 2px;
}

.dropdownBodyWrapper {
  top: 100%;
}

.dropdown:before {
  z-index: 990;
  top: calc(100% - 10px);
}

.dropdownFooter {
  text-align: center;
  padding: 9px;
}

.rewardsLastChild {
  border-bottom: none;
}

.code {
  margin: 0 5px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
}

.promoDetail {
  width: 125%;
}

.promoIconWrapper {
  color: #c2a661;
  justify-content: center;
  width: 50px;
  height: 50px;
  display: inline-block;
  position: relative;
}

.promoIconImg {
  width: 100%;
  height: 100%;
}

.notification {
  padding: 18px;
  text-decoration: none;
  display: block;
  position: relative;
}

.notification:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.notification:hover {
  color: unset;
  background: #f3f3f3;
}

.notification.unread {
  background: #f3f3f3;
}

.notification.unread:before {
  content: "";
  background: #c2a661;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 50%;
  left: 7px;
}

.heading {
  justify-content: space-between;
  display: flex;
}

.headingDetail {
  color: #222;
}

.notificationBody {
  margin: 9px 0;
  display: flex;
}

.arrow {
  color: #c2a661;
  align-self: center;
  width: 12px;
  height: 12px;
  margin-left: 18px;
}

.hidden {
  display: none;
}
