.likesWrapper {
  height: 100%;
  position: relative;
}

.noHover {
  pointer-events: none;
}

.dropdownBodyWrapper {
  top: 100%;
}

.dropdown:before {
  z-index: 990;
  top: calc(100% - 10px);
}
