.wrapper {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  color: #222;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}

.dropdown {
  opacity: 0;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  visibility: hidden;
}
.dropdown.hasDropdownHeader::before {
  border-bottom: 10px solid #f3f3f3;
}
.dropdown::before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  top: calc(100% - 10px);
  left: calc(50% - 10px);
  transition: opacity 0.2s ease, visibility 0.2s ease;
  z-index: 200;
}

.isVisible {
  visibility: visible;
  opacity: 1;
}

.bodyWrapper {
  box-shadow: 1px 2px 8px 0 rgba(34, 34, 34, 0.25);
  z-index: 980;
  background: #fff;
  position: absolute;
  top: 100%;
}
.bodyWrapper.small {
  width: 250px;
}
.bodyWrapper.medium {
  width: 350px;
}
.bodyWrapper.large {
  width: 450px;
}
.bodyWrapper.extraLarge {
  width: 550px;
}

.body {
  position: relative;
  overflow-y: scroll;
  max-height: 600px;
}

.header {
  display: flex;
  padding: 9px 18px;
  justify-content: space-between;
  background-color: #f3f3f3;
}

.headerTitle {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
}

.headerLink {
  font-size: 13px;
}