.form {
  display: flex;
  margin: 0;
  padding: 0;
}

.boldTerm {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 600;
}

.wrapper {
  position: relative;
  z-index: 0;
}

.searchBar {
  width: 300px;
  transition: all 0.2s ease;
}
.searchBar.isActive {
  width: 400px;
}

.overlayWrapper {
  position: absolute;
  width: 100%;
  margin-top: -1px;
  z-index: -10;
}

.overlay {
  box-shadow: 1px 2px 8px 0 rgba(34, 34, 34, 0.25);
  background: #fff;
  display: none;
  width: 100%;
}
.overlay.isVisible {
  display: block;
  border-top: 2px solid #ddd;
}
@media (min-width: 569px) {
  .overlay {
    border: 1px solid #222;
    border-top: 0;
  }
}

@media (max-width: 768px) {
  .boldTerm {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 18px;
    font-weight: 600;
  }
}