.wrapper {
  display: flex;
}

.iconWrapper {
  color: #000;
  text-align: center;
  width: 130px;
  max-height: 45px;
  line-height: 0;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.iconWrapper:hover {
  color: #000;
}

@media (width <= 768px) {
  .iconWrapper {
    width: 120px;
  }
}

.stickyLogo {
  width: 88px;
}
