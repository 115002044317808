/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.wrapper {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  display: flex;
  color: #222;
  position: static;
}

.dropdown {
  visibility: visible;
  opacity: 1;
}
.dropdown:not(.isVisible) {
  /*
  remove from accessibiliy tree so that items in dropdown are only usable
  when mouse events trigger the dropdown.
  */
  visibility: hidden;
  pointer-events: none;
}

.isVisible {
  visibility: visible;
  opacity: 1;
}

.bodyWrapper {
  height: 432px;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  top: calc(100% + 1px);
  background: #fff;
  transition: opacity 0.4s ease;
  transition-delay: 0.2s;
  width: 100%;
  box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.16);
  z-index: 980;
}
.bodyWrapper.isVisible {
  height: auto;
  min-height: 432px;
  opacity: 1;
  overflow: visible;
  transition-delay: 0s;
}