/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.bannerContainer {
  z-index: 200;
  display: flex;
  align-items: center;
  background: #222;
  min-height: 31px;
  height: 0;
  position: relative;
  overflow: hidden;
}
@media (max-width: 568px) {
  .bannerContainer {
    min-height: 76px;
    width: 100%;
    height: auto;
  }
}

@media (max-width: 568px) {
  .unsetMobileHeight {
    min-height: unset;
  }
}

:global(.footer-message-banner) {
  z-index: 300;
  bottom: 0;
  position: sticky;
}

.carouselWrapper {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 36px;
  width: 100%;
}
.carouselWrapper.hasPagination {
  justify-content: space-between;
}
@media (max-width: 568px) {
  .carouselWrapper.hasPagination {
    padding: 0 9px;
  }
}

.carousel {
  overflow: hidden;
  width: 100%;
}

.carouselItems {
  display: flex;
  align-items: center;
  position: relative;
  transition: transform 250ms linear;
}
.carouselItems.noTransition {
  transition: none;
}

.carouselArrowWrapperLeft {
  margin: auto;
  margin-right: 2%;
}

.carouselArrowWrapperRight {
  margin: auto;
  margin-left: 2%;
}

.carouselArrowLeft,
.carouselArrowRight {
  cursor: pointer;
  width: 19px;
  height: 19px;
  padding-top: 4px;
  fill: #c2a661;
  stroke: #c2a661;
  stroke-width: 3px;
}

.itemWrapper {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  flex: 0 1 100%;
  text-align: center;
}
@media (min-width: 569px) {
  .itemWrapper {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
@media (max-width: 568px) {
  .itemWrapper {
    line-height: 1.3;
    padding: 9px 0;
  }
}

.itemContent {
  color: #fff;
}

.ctaLink {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  color: #fff;
}

.closeIconWrapper {
  cursor: pointer;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 12px;
  color: #fff;
}
@media (max-width: 568px) {
  .closeIconWrapper {
    width: 18px;
    height: 18px;
    right: 10px;
  }
}

.closeIcon {
  fill: #fff;
}