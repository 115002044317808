.section {
  border-bottom: 1px solid #000;
  padding: 18px 0;
}
.section:first-child {
  padding-top: 9px;
}
.section:last-child {
  border: none;
}
@media (max-width: 768px) {
  .section {
    border-bottom: 0;
    padding-top: 27px;
  }
  .section:first-child {
    padding: 27px 0 0 0;
  }
  .section:last-child .header {
    margin-bottom: 9px;
  }
}

.header {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 0;
  padding: 4px 9px;
  color: #666;
}
@media (max-width: 768px) {
  .header {
    font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    margin: 0 18px;
    padding: 0 0 9px 0;
    border-bottom: 1px solid #ddd;
    color: #000;
  }
}