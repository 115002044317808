.navigation {
  justify-content: center;
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
}

.items {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.item {
  cursor: pointer;
  align-content: center;
  display: flex;
}

.item:not(:first-child) > .fullWrapper {
  padding-left: 9px;
}

.item:not(:last-child) > .fullWrapper {
  padding-right: 9px;
}

.itemLink {
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.itemLink:hover {
  color: #000;
}

@media (width <= 1200px) {
  .itemLink {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 12px;
    font-weight: 300;
  }
}

.itemLink.pale {
  color: #bbb;
}

.linkUnderline {
  background: #222;
  width: 100%;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.linkUnderlineTrade {
  composes: linkUnderline;
  background: #222;
}

.dropdownContent {
  width: calc(100% - 72px);
  width: calc(100% - 2 * var(--sassy-layout-offset));
  max-width: 1440px;
  min-height: 432px;
  margin: 0 auto;
  display: flex;
}

.dropdownHeader {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.dropdownCol {
  width: 20%;
  margin-left: 36px;
  padding: 27px 0;
}

.dropdownCol:first-child {
  margin-left: 0;
}

.dropdownCol:last-child.imageModule {
  flex-grow: 1;
  width: 400px;
}

.dropdownLink {
  color: #222;
  padding-top: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  line-height: 32px;
  text-decoration: none;
  display: block;
}

.dropdownLink:hover {
  color: #222;
  text-decoration: underline;
}

.imageModuleWrapper {
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.imageModuleImage {
  opacity: 0;
  width: auto;
  height: 100%;
  transition: opacity .5s;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.imageModuleImage.setWidth {
  width: 100%;
  height: auto;
}

.imageModuleImage.loadedImage {
  opacity: 1;
}

.imageModuleLink {
  width: 400px;
  height: 300px;
  margin-left: auto;
  display: block;
}

.imageBox {
  width: 400px;
  height: 200px;
  margin-left: auto;
  position: relative;
  overflow: hidden;
}

.titleBox {
  color: #222;
  background: #fff;
  flex-wrap: wrap;
  align-items: center;
  width: 400px;
  height: 100px;
  display: flex;
  position: absolute;
  top: 200px;
  right: 0;
}

.titleSecondary {
  width: 100%;
  margin: 18px 18px 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.titlePrimary {
  letter-spacing: -.5px;
  width: 100%;
  margin: 9px 18px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.dropdownSection {
  margin-top: 20px;
}

.dropdownSection:first-child {
  margin-top: 0;
}
