/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.likesWrapper {
  position: relative;
  height: 100%;
}

.noHover {
  pointer-events: none;
}

.dropdownBodyWrapper {
  top: 100%;
}

.dropdown:before {
  top: calc(100% - 9px - 1px);
  z-index: 990;
}