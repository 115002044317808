.wrapper {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
  position: relative;
  display: flex;
}
.wrapper .switch {
  position: relative;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  outline: none;
}
.wrapper .switch .slider {
  width: 63px;
  height: 30px;
  padding: 0;
  transition: all 0.2s ease;
  border: thin solid #bbb;
  border-radius: 9999px;
  background: #bbb;
}
.wrapper .switch:focus-visible::after {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border: 1px solid #222;
  border-radius: 9999px;
  z-index: 300;
}
.wrapper .switch.isChecked .slider {
  background: #222;
  border: thin solid #222;
}
.wrapper .switch.isChecked .slider .sliderOn {
  opacity: 1;
  transition: opacity 0.25s ease;
}
.wrapper .switch.isChecked .slider .sliderOff {
  opacity: 0;
}
.wrapper .switch.isChecked .dot {
  left: 36px;
}
.wrapper.isDisabled {
  pointer-events: none;
}
.wrapper.isDisabled .switch .slider {
  background-color: #ddd;
  border: thin solid #ddd;
}

.label {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.25;
  margin: auto 18px auto 0;
  position: relative;
}

.dot {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 4px;
  left: 5px;
  transition: all 0.25s ease;
  border-radius: 9999px;
  box-sizing: border-box;
  background: #fff;
}

.sliderOn {
  left: 5px;
  opacity: 0;
}

.sliderOff {
  right: 5px;
  opacity: 1;
}

.icon {
  position: absolute;
  top: 5px;
  fill: #fff;
  user-select: none;
  transition: opacity 0.25s ease;
}