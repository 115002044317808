:local .textInputWrapper {
  height: 100%;
}

:local .textInputWrapper span {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="text"] {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="password"] {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="datetime"] {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="datetime-local"] {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="date"] {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="month"] {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="time"] {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="week"] {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="number"] {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="email"] {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="url"] {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="search"] {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="tel"] {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="color"] {
  appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  border-radius: 0;
  outline: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  position: relative;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: #0000 !important;
  background-image: none !important;
  border: none !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  transition: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper span:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="text"]:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="password"]:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="datetime"]:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="datetime-local"]:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="date"]:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="month"]:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="time"]:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="week"]:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="number"]:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="email"]:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="url"]:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="search"]:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="tel"]:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper input[type="color"]:focus {
  border: #0000 !important;
  outline: none !important;
  box-shadow: inset 0 0 0 30px #fff !important;
}

:local .textInputWrapper span:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper input:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper input[type="text"]:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper input[type="password"]:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper input[type="datetime"]:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper input[type="datetime-local"]:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper input[type="date"]:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper input[type="month"]:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper input[type="time"]:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper input[type="week"]:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper input[type="number"]:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper input[type="email"]:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper input[type="url"]:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper input[type="search"]:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper input[type="tel"]:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper input[type="color"]:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: inset 0 0 0 30px #ddd !important;
}

:local .textInputWrapper.isHighlighted span {
  box-shadow: none !important;
}

:local .textInputWrapper.isHighlighted input {
  box-shadow: none !important;
}

:local .textInputWrapper.isHighlighted input[type="text"] {
  box-shadow: none !important;
}

:local .textInputWrapper.isHighlighted input[type="password"] {
  box-shadow: none !important;
}

:local .textInputWrapper.isHighlighted input[type="datetime"] {
  box-shadow: none !important;
}

:local .textInputWrapper.isHighlighted input[type="datetime-local"] {
  box-shadow: none !important;
}

:local .textInputWrapper.isHighlighted input[type="date"] {
  box-shadow: none !important;
}

:local .textInputWrapper.isHighlighted input[type="month"] {
  box-shadow: none !important;
}

:local .textInputWrapper.isHighlighted input[type="time"] {
  box-shadow: none !important;
}

:local .textInputWrapper.isHighlighted input[type="week"] {
  box-shadow: none !important;
}

:local .textInputWrapper.isHighlighted input[type="number"] {
  box-shadow: none !important;
}

:local .textInputWrapper.isHighlighted input[type="email"] {
  box-shadow: none !important;
}

:local .textInputWrapper.isHighlighted input[type="url"] {
  box-shadow: none !important;
}

:local .textInputWrapper.isHighlighted input[type="search"] {
  box-shadow: none !important;
}

:local .textInputWrapper.isHighlighted input[type="tel"] {
  box-shadow: none !important;
}

:local .textInputWrapper.isHighlighted input[type="color"] {
  box-shadow: none !important;
}

:local .textInputWrapper.headerInput span {
  color: #000 !important;
}

:local .textInputWrapper.headerInput input {
  color: #000 !important;
}

:local .textInputWrapper.headerInput input[type="text"] {
  color: #000 !important;
}

:local .textInputWrapper.headerInput input[type="password"] {
  color: #000 !important;
}

:local .textInputWrapper.headerInput input[type="datetime"] {
  color: #000 !important;
}

:local .textInputWrapper.headerInput input[type="datetime-local"] {
  color: #000 !important;
}

:local .textInputWrapper.headerInput input[type="date"] {
  color: #000 !important;
}

:local .textInputWrapper.headerInput input[type="month"] {
  color: #000 !important;
}

:local .textInputWrapper.headerInput input[type="time"] {
  color: #000 !important;
}

:local .textInputWrapper.headerInput input[type="week"] {
  color: #000 !important;
}

:local .textInputWrapper.headerInput input[type="number"] {
  color: #000 !important;
}

:local .textInputWrapper.headerInput input[type="email"] {
  color: #000 !important;
}

:local .textInputWrapper.headerInput input[type="url"] {
  color: #000 !important;
}

:local .textInputWrapper.headerInput input[type="search"] {
  color: #000 !important;
}

:local .textInputWrapper.headerInput input[type="tel"] {
  color: #000 !important;
}

:local .textInputWrapper.headerInput input[type="color"] {
  color: #000 !important;
}

:local .textInputWrapper input::placeholder {
  color: #666;
}

:local .textInputWrapper input:read-only {
  cursor: pointer;
}

:local .animatedPlaceholderPadding span {
  padding-top: 15px !important;
}

:local .animatedPlaceholderPadding input {
  padding-top: 15px !important;
}

:local .animatedPlaceholderPadding input[type="text"] {
  padding-top: 15px !important;
}

:local .animatedPlaceholderPadding input[type="password"] {
  padding-top: 15px !important;
}

:local .animatedPlaceholderPadding input[type="datetime"] {
  padding-top: 15px !important;
}

:local .animatedPlaceholderPadding input[type="datetime-local"] {
  padding-top: 15px !important;
}

:local .animatedPlaceholderPadding input[type="date"] {
  padding-top: 15px !important;
}

:local .animatedPlaceholderPadding input[type="month"] {
  padding-top: 15px !important;
}

:local .animatedPlaceholderPadding input[type="time"] {
  padding-top: 15px !important;
}

:local .animatedPlaceholderPadding input[type="week"] {
  padding-top: 15px !important;
}

:local .animatedPlaceholderPadding input[type="number"] {
  padding-top: 15px !important;
}

:local .animatedPlaceholderPadding input[type="email"] {
  padding-top: 15px !important;
}

:local .animatedPlaceholderPadding input[type="url"] {
  padding-top: 15px !important;
}

:local .animatedPlaceholderPadding input[type="search"] {
  padding-top: 15px !important;
}

:local .animatedPlaceholderPadding input[type="tel"] {
  padding-top: 15px !important;
}

:local .animatedPlaceholderPadding input[type="color"] {
  padding-top: 15px !important;
}

:local .usePaddingLeft span {
  padding-left: 58px !important;
}

:local .usePaddingLeft input {
  padding-left: 58px !important;
}

:local .usePaddingLeft input[type="text"] {
  padding-left: 58px !important;
}

:local .usePaddingLeft input[type="password"] {
  padding-left: 58px !important;
}

:local .usePaddingLeft input[type="datetime"] {
  padding-left: 58px !important;
}

:local .usePaddingLeft input[type="datetime-local"] {
  padding-left: 58px !important;
}

:local .usePaddingLeft input[type="date"] {
  padding-left: 58px !important;
}

:local .usePaddingLeft input[type="month"] {
  padding-left: 58px !important;
}

:local .usePaddingLeft input[type="time"] {
  padding-left: 58px !important;
}

:local .usePaddingLeft input[type="week"] {
  padding-left: 58px !important;
}

:local .usePaddingLeft input[type="number"] {
  padding-left: 58px !important;
}

:local .usePaddingLeft input[type="email"] {
  padding-left: 58px !important;
}

:local .usePaddingLeft input[type="url"] {
  padding-left: 58px !important;
}

:local .usePaddingLeft input[type="search"] {
  padding-left: 58px !important;
}

:local .usePaddingLeft input[type="tel"] {
  padding-left: 58px !important;
}

:local .usePaddingLeft input[type="color"] {
  padding-left: 58px !important;
}
