.wrapper {
  z-index: 980;
  background-color: #fff;
  position: relative;
}

.wrapper * {
  box-sizing: inherit;
}

@media print {
  .wrapper {
    display: none;
  }
}

.header {
  z-index: 980;
  width: 100%;
  height: 94px;
  position: fixed;
}

:global(.primary-resp-page-width) .header {
  min-width: 1024px;
}

:global(.primary-legacy-resp-page-width) .header {
  min-width: 768px;
}

.header.hasBanner {
  height: 31px;
  position: relative;
}

.header.hasBanner.isBannerOutOfScreen {
  height: 64px;
  position: fixed;
  top: -31px;
}

.header.disableSticky {
  height: 135px;
  position: static;
}

.header.disableSticky.hasBanner {
  margin-bottom: 31px;
}

.header.hasTopBarOnly {
  height: 94px;
}

.header.hideBottomBar {
  height: 64px;
}

.bottomWrapper.disableSticky {
  border-bottom: 1px solid #ddd;
  position: relative;
}

.shadow {
  z-index: -2;
  height: 135px;
  position: relative;
}

.shadow.hasTopBarOnly {
  height: 94px;
}

.shadow.disableSticky {
  display: none;
}

.shadow.isBannerOutOfScreen {
  height: 166px;
}

.shadow.hideBottomBar {
  height: 64px;
}

.bar {
  background: #222;
  justify-content: center;
  display: flex;
  position: relative;
}

.barContent {
  width: calc(100% - 72px);
  width: calc(100% - 2 * var(--sassy-layout-offset));
  align-items: center;
  max-width: 1440px;
  position: static;
}

.headerLink {
  color: #fff;
  text-decoration: none;
}

.headerLink a:hover, .headerLink:hover {
  color: #fff;
  text-decoration: underline;
}

.skipToContent {
  position: fixed;
  top: 9px;
  left: 9px;
  transition: none !important;
}

.skipToContent:not(:focus) {
  clip: rect(0 0 0 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.skipToContent:focus {
  z-index: 10020;
}
