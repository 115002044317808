:local .animatedPlaceholderContainer {
  flex-grow: 1;
  position: relative;
}

:local .animatedPlaceholderStart {
  color: #666;
  pointer-events: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 10;
  width: 100%;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  line-height: 50px;
  transition: font-size .1s, line-height .1s, top .1s;
  position: absolute;
  top: 0;
  left: 1px;
  overflow: hidden;
}

:local .animatedPlaceholderEnd {
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  display: block;
  top: 8px;
}

:local .marginLeftMedium {
  margin-left: 18px;
}

:local .marginLeftSmall {
  margin-left: 9px;
}

:local .marginRightMedium {
  margin-right: 18px;
}

:local .marginRightSmall {
  margin-right: 9px;
}

:local .placeholderPaddingLeftMedium {
  padding-left: 18px;
}

:local .placeholderPaddingLeftSmall {
  padding-left: 9px;
}
