.bar {
  z-index: 10;
  background: #fff;
  justify-content: center;
  height: 94px;
  display: flex;
  position: relative;
  -webkit-transform: translateZ(0);
  box-shadow: 0 2px 8px #0000;
}

.stickyBar {
  height: 64px;
  transition: all .3s;
  box-shadow: 0 2px 8px #00000014;
}

.barContent {
  width: calc(100% - 72px);
  width: calc(100% - 2 * var(--sassy-layout-offset));
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  display: flex;
  position: static;
}

.leftContainer {
  flex: 1;
  align-items: center;
  display: flex;
}

.rightContainer {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  display: flex;
}
