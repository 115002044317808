/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.iconWrapper {
  display: flex;
  align-items: center;
}

.icon {
  cursor: pointer;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin: 0 9px;
  position: relative;
  text-decoration: none;
  color: #000;
}
.icon:hover {
  color: #000;
}

.hasUnread:after {
  display: block;
  content: "";
  position: absolute;
  right: -4px;
  top: -1px;
  width: 9px;
  height: 9px;
  border-radius: 999px;
  background: #cc0000;
}