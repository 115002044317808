/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.wrapper {
  display: flex;
  position: relative;
  height: 100%;
}

.noHover {
  pointer-events: none;
}

.authButtons {
  align-items: center;
  display: flex;
}

.authButton {
  font-size: 14px;
  font-weight: 300;
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
  background: none;
  border: none;
  border-left: 1px solid #222;
  cursor: pointer;
  padding: 0 9px;
}
.authButton:hover {
  color: #444;
}
@media (hover: none) {
  .authButton:hover {
    color: #000;
  }
}
.authButton:nth-child(1) {
  border: none;
}

.dropdownBodyWrapper {
  top: 100%;
}

.dropdown:before {
  top: calc(100% - 9px - 1px);
  z-index: 990;
}

.dropdownContent {
  padding: 18px 27px;
}

.dropdownLinkWrapper {
  padding: 5px;
  position: relative;
}

.badgeContainer {
  position: absolute;
  right: 0;
}

.dropdownLink {
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
}
.dropdownLink:hover {
  color: #444;
}
@media (hover: none) {
  .dropdownLink:hover {
    color: #000;
  }
}

.unreadCount {
  color: #cc0000;
}
.unreadCount:hover {
  color: #cc0000;
}