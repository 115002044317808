:local .wrapper {
  display: flex;
}
:local .decoratorWrapper {
  padding: 0 !important;
}
:local .defaultSearch {
  font-size: 16px;
  font-weight: 300;
  padding: 12px;
  height: 50px;
  width: 50px;
  overflow: hidden;
}
:local .smallSearch {
  font-size: 16px;
  font-weight: 300;
  font-size: 12px;
  font-weight: 300;
  padding: 7.5px;
  height: 33px;
  width: 33px;
  overflow: hidden;
}
:local .mediumSearch {
  font-size: 16px;
  font-weight: 300;
  font-size: 14px;
  font-weight: 300;
  padding: 9px;
  height: 40px;
  width: 40px;
  overflow: hidden;
}
:local .largeSearch {
  font-size: 16px;
  font-weight: 300;
  padding: 12px;
  height: 50px;
  width: 50px;
  overflow: hidden;
}
:local .defaultButton {
  z-index: 10;
  font-size: 16px;
  font-weight: 300;
  padding: 14px;
  height: 50px;
  width: 50px;
  overflow: hidden;
}
:local .smallButton {
  font-size: 16px;
  font-weight: 300;
  font-size: 12px;
  font-weight: 300;
  padding: 9.5px;
  height: 33px;
  width: 33px;
  overflow: hidden;
}
:local .mediumButton {
  font-size: 16px;
  font-weight: 300;
  font-size: 14px;
  font-weight: 300;
  padding: 11px;
  height: 40px;
  width: 40px;
  overflow: hidden;
}
:local .largeButton {
  font-size: 16px;
  font-weight: 300;
  padding: 14px;
  height: 50px;
  width: 50px;
  overflow: hidden;
}
:local .fullyTransparent {
  background-color: transparent;
  color: #222;
}
:local .whiteFill {
  fill: #fff;
}
:local .hideButton {
  opacity: 0;
  width: 0;
  padding: 0;
  transition: opacity 175ms linear, width 0ms linear 175ms, padding 0ms linear 175ms !important;
}
:local .showButton {
  opacity: 1;
  transition: opacity 175ms linear;
}
:local .shiftRight {
  margin-right: -1px;
}
:local .noClick {
  pointer-events: none;
}
:local .hideDropdown {
  display: none;
  opacity: 0;
}

.headerSearchButton {
  padding: 10px;
  color: #000;
}

.headerSearchClearButton {
  padding: 13px;
  color: #000;
}

.openDropdown {
  border: 1px solid #000;
  flex: 1;
}