.iconWrapper {
  align-items: center;
  display: flex;
}

.noHover {
  pointer-events: none;
}

.icon {
  cursor: pointer;
  color: #000;
  width: 18px;
  margin: 0 9px;
  padding-top: 9px;
  text-decoration: none;
  position: relative;
  bottom: 1px;
}

.icon:hover {
  color: #000;
}

.countWrapper {
  width: 100%;
  font-size: 12px;
  font-weight: 300;
  position: absolute;
}

.count {
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 10px;
  font-weight: 300;
  line-height: 16px;
  position: relative;
  bottom: 3px;
  left: 16px;
}

.maxCount {
  padding-left: 3px;
}

.emptyBag {
  text-align: center;
  padding: 18px;
  font-size: 14px;
}

.dropdownBodyWrapper {
  top: 100%;
}

.dropdown:before {
  z-index: 990;
  top: calc(100% - 10px);
}

.dropdownTotalItemsRow {
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 1px solid #ddd;
  padding: 9px 18px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 12px;
  font-weight: 300;
}

.dropdownItemRow {
  cursor: pointer;
  text-align: left;
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 18px 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  transition: background .4s;
  display: flex;
}

.dropdownItemRow:hover {
  background: #f3f3f3;
}

.dropdownRowLink {
  color: #222;
}

.dropdownItemColumn {
  padding: 0 18px;
}

.dropdownItemImageWrapper {
  width: 75px;
  height: 75px;
  position: relative;
}

.dropdownItemImage {
  max-width: 75px;
  max-height: 75px;
  margin: auto;
  position: absolute;
  inset: 0;
}

.dropdownItemTitle > a {
  color: #000;
  cursor: pointer;
  text-decoration: none;
  transition: color .2s;
}

.dropdownItemTitle > a:hover {
  color: #444;
}

@media (hover: none) {
  .dropdownItemTitle > a:hover {
    color: #000;
  }
}

.alert {
  color: #950808;
}
