:local .arrowContainer {
  justify-content: center;
  align-items: center;
  display: flex;
}

:local .arrow {
  transition: transform .3s ease-in-out, fill .3s ease-in-out;
  max-width: none !important;
}

:local .sizeSmall {
  width: 14px;
}

:local .sizeMedium {
  width: 16px;
}

:local .sizeHeaderXLarge {
  width: 28px;
}

:local .default {
  fill: #666;
}

:local .defaultHover {
  fill: #666;
}

:local .whiteFill {
  fill: #fff;
}

:local .whiteFill:hover {
  fill: #c2a661;
  cursor: pointer;
}

:local .blackFill {
  fill: #000;
}

:local .blackFill:hover {
  cursor: pointer;
}

:local .linkPrimary {
  fill: #222;
}

:local .linkPrimary:hover {
  fill: #c2a661;
  cursor: pointer;
}

@media (hover: none) {
  :local .linkPrimary:hover {
    color: #222;
  }
}

:local .linkPrimaryHover {
  cursor: pointer;
  fill: #c2a661 !important;
}

@media (hover: none) {
  :local .linkPrimaryHover {
    fill: #222 !important;
  }

  :local .linkPrimaryHover:hover {
    fill: #222 !important;
  }
}

:local .disabled {
  fill: #bbb;
}

:local .disabled:hover {
  cursor: not-allowed;
}

:local .arrowDown {
  transform: rotate(180deg);
}

:local .paddingRight {
  padding-right: 18px;
}

:local .paddingRightSmall {
  padding-right: 9px;
}
