.wrapper {
  color: #222;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  display: flex;
  position: static;
}

.dropdown {
  visibility: visible;
  opacity: 1;
}

.dropdown:not(.isVisible) {
  visibility: hidden;
  pointer-events: none;
}

.isVisible {
  visibility: visible;
  opacity: 1;
}

.bodyWrapper {
  opacity: 0;
  z-index: 980;
  background: #fff;
  width: 100%;
  height: 432px;
  transition: opacity .4s .2s;
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  overflow: hidden;
  box-shadow: 0 2px 8px #00000029;
}

.bodyWrapper.isVisible {
  opacity: 1;
  height: auto;
  min-height: 432px;
  transition-delay: 0s;
  overflow: visible;
}
