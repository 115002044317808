.form {
  margin: 0;
  padding: 0;
  display: flex;
}

.boldTerm {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 600;
}

.wrapper {
  z-index: 0;
  position: relative;
}

.searchBar {
  width: 300px;
  transition: all .2s;
}

.searchBar.isActive {
  width: 400px;
}

.overlayWrapper {
  z-index: -10;
  width: 100%;
  margin-top: -1px;
  position: absolute;
}

.overlay {
  background: #fff;
  width: 100%;
  display: none;
  box-shadow: 1px 2px 8px #22222240;
}

.overlay.isVisible {
  border-top: 2px solid #ddd;
  display: block;
}

@media (width >= 569px) {
  .overlay {
    border: 1px solid #222;
    border-top: 0;
  }
}

@media (width <= 768px) {
  .boldTerm {
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 18px;
    font-weight: 600;
  }
}
