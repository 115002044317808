.item {
  padding: 0;
}

.item.active, .item:hover {
  background: #f6f3eb;
}

@media (width <= 768px) {
  .item {
    padding: 9px 0;
  }
}

.link {
  color: #000;
  cursor: pointer;
  width: 100%;
  padding: 4px 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  display: inline-block;
}

.link:hover, .link:visited {
  color: #222;
}

@media (width <= 768px) {
  .link {
    padding: 0 18px;
    font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
    font-size: 18px;
    font-weight: 300;
  }
}
