:local .inputContainer {
  position: relative;
  border: thin solid #ddd;
  display: flex;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 300;
  background: #fff;
  box-sizing: border-box;
  transition: border-color 0.15s ease-in;
}
:local .borderError {
  border-color: #cc0000;
}
:local .borderDark {
  border-color: #000 !important;
}
:local .disabled {
  background: #ddd !important;
  cursor: not-allowed;
}
:local .borderFocus {
  border-color: #222;
}
:local .heightLarge {
  height: 50px;
}
:local .heightMedium {
  height: 40px;
}
:local .heightSmall {
  height: 33px;
}
:local .heightDefault {
  height: 50px;
}
:local .noBorder {
  border: none;
}