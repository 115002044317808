:local .textInputWrapper {
  height: 100%;
}
:local .textInputWrapper span,
:local .textInputWrapper input,
:local .textInputWrapper input[type=text],
:local .textInputWrapper input[type=password],
:local .textInputWrapper input[type=datetime],
:local .textInputWrapper input[type=datetime-local],
:local .textInputWrapper input[type=date],
:local .textInputWrapper input[type=month],
:local .textInputWrapper input[type=time],
:local .textInputWrapper input[type=week],
:local .textInputWrapper input[type=number],
:local .textInputWrapper input[type=email],
:local .textInputWrapper input[type=url],
:local .textInputWrapper input[type=search],
:local .textInputWrapper input[type=tel],
:local .textInputWrapper input[type=color] {
  width: 100% !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  color: #222 !important;
  vertical-align: middle !important;
  background-color: transparent !important;
  background-image: none !important;
  border: none !important;
  border-radius: 0px;
  box-shadow: 0 0 0 30px #fff inset !important;
  transition: none !important;
  height: 100% !important;
  padding: 0 !important;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 300;
  appearance: none;
  box-sizing: border-box;
  outline: none;
  text-overflow: ellipsis;
  position: relative;
}
:local .textInputWrapper span:focus,
:local .textInputWrapper input:focus,
:local .textInputWrapper input[type=text]:focus,
:local .textInputWrapper input[type=password]:focus,
:local .textInputWrapper input[type=datetime]:focus,
:local .textInputWrapper input[type=datetime-local]:focus,
:local .textInputWrapper input[type=date]:focus,
:local .textInputWrapper input[type=month]:focus,
:local .textInputWrapper input[type=time]:focus,
:local .textInputWrapper input[type=week]:focus,
:local .textInputWrapper input[type=number]:focus,
:local .textInputWrapper input[type=email]:focus,
:local .textInputWrapper input[type=url]:focus,
:local .textInputWrapper input[type=search]:focus,
:local .textInputWrapper input[type=tel]:focus,
:local .textInputWrapper input[type=color]:focus {
  border: none !important;
  border-color: transparent !important;
  outline: none !important;
  box-shadow: 0 0 0 30px #fff inset !important;
}
:local .textInputWrapper span:disabled,
:local .textInputWrapper input:disabled,
:local .textInputWrapper input[type=text]:disabled,
:local .textInputWrapper input[type=password]:disabled,
:local .textInputWrapper input[type=datetime]:disabled,
:local .textInputWrapper input[type=datetime-local]:disabled,
:local .textInputWrapper input[type=date]:disabled,
:local .textInputWrapper input[type=month]:disabled,
:local .textInputWrapper input[type=time]:disabled,
:local .textInputWrapper input[type=week]:disabled,
:local .textInputWrapper input[type=number]:disabled,
:local .textInputWrapper input[type=email]:disabled,
:local .textInputWrapper input[type=url]:disabled,
:local .textInputWrapper input[type=search]:disabled,
:local .textInputWrapper input[type=tel]:disabled,
:local .textInputWrapper input[type=color]:disabled {
  cursor: not-allowed;
  background-color: #ddd !important;
  box-shadow: 0 0 0 30px #ddd inset !important;
}
:local .textInputWrapper.isHighlighted span,
:local .textInputWrapper.isHighlighted input,
:local .textInputWrapper.isHighlighted input[type=text],
:local .textInputWrapper.isHighlighted input[type=password],
:local .textInputWrapper.isHighlighted input[type=datetime],
:local .textInputWrapper.isHighlighted input[type=datetime-local],
:local .textInputWrapper.isHighlighted input[type=date],
:local .textInputWrapper.isHighlighted input[type=month],
:local .textInputWrapper.isHighlighted input[type=time],
:local .textInputWrapper.isHighlighted input[type=week],
:local .textInputWrapper.isHighlighted input[type=number],
:local .textInputWrapper.isHighlighted input[type=email],
:local .textInputWrapper.isHighlighted input[type=url],
:local .textInputWrapper.isHighlighted input[type=search],
:local .textInputWrapper.isHighlighted input[type=tel],
:local .textInputWrapper.isHighlighted input[type=color] {
  box-shadow: none !important;
}
:local .textInputWrapper.headerInput span,
:local .textInputWrapper.headerInput input,
:local .textInputWrapper.headerInput input[type=text],
:local .textInputWrapper.headerInput input[type=password],
:local .textInputWrapper.headerInput input[type=datetime],
:local .textInputWrapper.headerInput input[type=datetime-local],
:local .textInputWrapper.headerInput input[type=date],
:local .textInputWrapper.headerInput input[type=month],
:local .textInputWrapper.headerInput input[type=time],
:local .textInputWrapper.headerInput input[type=week],
:local .textInputWrapper.headerInput input[type=number],
:local .textInputWrapper.headerInput input[type=email],
:local .textInputWrapper.headerInput input[type=url],
:local .textInputWrapper.headerInput input[type=search],
:local .textInputWrapper.headerInput input[type=tel],
:local .textInputWrapper.headerInput input[type=color] {
  color: #000 !important;
}
:local .textInputWrapper input::placeholder {
  color: #666;
}
:local .textInputWrapper input:read-only {
  cursor: pointer;
}
:local .animatedPlaceholderPadding span,
:local .animatedPlaceholderPadding input,
:local .animatedPlaceholderPadding input[type=text],
:local .animatedPlaceholderPadding input[type=password],
:local .animatedPlaceholderPadding input[type=datetime],
:local .animatedPlaceholderPadding input[type=datetime-local],
:local .animatedPlaceholderPadding input[type=date],
:local .animatedPlaceholderPadding input[type=month],
:local .animatedPlaceholderPadding input[type=time],
:local .animatedPlaceholderPadding input[type=week],
:local .animatedPlaceholderPadding input[type=number],
:local .animatedPlaceholderPadding input[type=email],
:local .animatedPlaceholderPadding input[type=url],
:local .animatedPlaceholderPadding input[type=search],
:local .animatedPlaceholderPadding input[type=tel],
:local .animatedPlaceholderPadding input[type=color] {
  padding-top: 15px !important;
}
:local .usePaddingLeft span,
:local .usePaddingLeft input,
:local .usePaddingLeft input[type=text],
:local .usePaddingLeft input[type=password],
:local .usePaddingLeft input[type=datetime],
:local .usePaddingLeft input[type=datetime-local],
:local .usePaddingLeft input[type=date],
:local .usePaddingLeft input[type=month],
:local .usePaddingLeft input[type=time],
:local .usePaddingLeft input[type=week],
:local .usePaddingLeft input[type=number],
:local .usePaddingLeft input[type=email],
:local .usePaddingLeft input[type=url],
:local .usePaddingLeft input[type=search],
:local .usePaddingLeft input[type=tel],
:local .usePaddingLeft input[type=color] {
  padding-left: 58px !important;
}