.wrapper {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  display: flex;
  position: relative;
}

.wrapper .switch {
  cursor: pointer;
  outline: none;
  height: 30px;
  display: inline-block;
  position: relative;
}

.wrapper .switch .slider {
  background: #bbb;
  border: thin solid #bbb;
  border-radius: 9999px;
  width: 63px;
  height: 30px;
  padding: 0;
  transition: all .2s;
}

.wrapper .switch:focus-visible:after {
  content: "";
  z-index: 300;
  border: 1px solid #222;
  border-radius: 9999px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  position: absolute;
  top: -2px;
  left: -2px;
}

.wrapper .switch.isChecked .slider {
  background: #222;
  border: thin solid #222;
}

.wrapper .switch.isChecked .slider .sliderOn {
  opacity: 1;
  transition: opacity .25s;
}

.wrapper .switch.isChecked .slider .sliderOff {
  opacity: 0;
}

.wrapper .switch.isChecked .dot {
  left: 36px;
}

.wrapper.isDisabled {
  pointer-events: none;
}

.wrapper.isDisabled .switch .slider {
  background-color: #ddd;
  border: thin solid #ddd;
}

.label {
  margin: auto 18px auto 0;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.25;
  position: relative;
}

.dot {
  box-sizing: border-box;
  background: #fff;
  border-radius: 9999px;
  width: 22px;
  height: 22px;
  transition: all .25s;
  position: absolute;
  top: 4px;
  left: 5px;
}

.sliderOn {
  opacity: 0;
  left: 5px;
}

.sliderOff {
  opacity: 1;
  right: 5px;
}

.icon {
  fill: #fff;
  -webkit-user-select: none;
  user-select: none;
  transition: opacity .25s;
  position: absolute;
  top: 5px;
}
