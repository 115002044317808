:local .errorMessage {
  color: #c00;
  width: 100%;
  margin-top: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

:local .errorMessage.showErrorAsNote {
  color: #222;
}

:local .errorMessageTransition {
  opacity: 0;
  transition: opacity .15s ease-in;
  animation: .15s ease-in fadeIn;
}

:local .errorMessageTransition.activeErrorMessageTransition {
  opacity: 1;
}

:local .errorMessageRight {
  text-align: right;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
