.bannerContainer {
  z-index: 200;
  background: #222;
  align-items: center;
  height: 0;
  min-height: 31px;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (width <= 568px) {
  .bannerContainer {
    width: 100%;
    height: auto;
    min-height: 76px;
  }

  .unsetMobileHeight {
    min-height: unset;
  }
}

:global(.footer-message-banner) {
  z-index: 300;
  position: sticky;
  bottom: 0;
}

.carouselWrapper {
  justify-content: center;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 36px;
  display: flex;
}

.carouselWrapper.hasPagination {
  justify-content: space-between;
}

@media (width <= 568px) {
  .carouselWrapper.hasPagination {
    padding: 0 9px;
  }
}

.carousel {
  width: 100%;
  overflow: hidden;
}

.carouselItems {
  align-items: center;
  transition: transform .25s linear;
  display: flex;
  position: relative;
}

.carouselItems.noTransition {
  transition: none;
}

.carouselArrowWrapperLeft {
  margin: auto 2% auto auto;
}

.carouselArrowWrapperRight {
  margin: auto auto auto 2%;
}

.carouselArrowLeft, .carouselArrowRight {
  cursor: pointer;
  fill: #c2a661;
  stroke: #c2a661;
  stroke-width: 3px;
  width: 19px;
  height: 19px;
  padding-top: 4px;
}

.itemWrapper {
  color: #fff;
  text-align: center;
  flex: 0 100%;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

@media (width >= 569px) {
  .itemWrapper {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media (width <= 568px) {
  .itemWrapper {
    padding: 9px 0;
    line-height: 1.3;
  }
}

.itemContent {
  color: #fff;
}

.ctaLink {
  color: #fff;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.closeIconWrapper {
  cursor: pointer;
  color: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
}

@media (width <= 568px) {
  .closeIconWrapper {
    width: 18px;
    height: 18px;
    right: 10px;
  }
}

.closeIcon {
  fill: #fff;
}
