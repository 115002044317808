:local .decorator {
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 0 9px;
  font-size: 16px;
  font-weight: 300;
  display: flex;
}

:local .iconContainer {
  justify-content: center;
  align-items: center;
  min-width: 32px;
  max-width: 32px;
  padding-right: 18px;
  display: flex;
}

:local .iconContainerSmall {
  width: 30px;
  max-width: 30px;
  padding-right: 9px;
}

:local .iconContainerLarge {
  min-width: 45px;
  max-width: 45px;
}

:local .errorIcon {
  fill: #c00;
  width: 100%;
  animation: .15s ease-in fadeIn;
  max-width: none !important;
}

:local .validatedIcon {
  fill: #66a559;
  width: 100%;
  animation: .15s ease-in fadeIn;
  max-width: none !important;
}

:local .hiddenPlaceholder {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
